import React, {Component} from "react";
import * as PropTypes from "prop-types";
import CircularProgress from "components/CircularProgress";

export default class ToggleButton extends Component{

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func,
        loading: PropTypes.bool,
        label: PropTypes.any,
        labelPosition: PropTypes.string,
        disabled: PropTypes.bool
    };

    render() {
        const {checked, loading, label, labelPosition = 'right', style} = this.props;
        const iconClassName = this.props.className || '';
        if (loading) {
            return (
                <CircularProgress/>
            );
        }
        let className = 'toggle-button';
        if (this.props.disabled) {
            className += ' disabled';
        }
        return (
            <div className={className} onClick={this.onClick} style={style}>
                {label && labelPosition === 'left' && <span>{label}</span>}
                <i className={`toggle-icon fa-duotone fa-toggle-${checked ? 'on on' : 'off'} ${iconClassName}`}/>
                {label && labelPosition === 'right' && <span>{label}</span>}
            </div>
        );
    }

    onClick(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.disabled) {
            return;
        }
        if(this.props.onChange) {
            this.props.onChange(!this.props.checked);
        }
    }
}