import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import EventBus from "./EventBus";
import Helpers from "./Helpers";
import {EventSourceMessage, getBytes, getLines, getMessages} from "@microsoft/fetch-event-source/lib/cjs/parse";

interface UploadRequestOptions {
    url: string;
    method?: 'POST' | 'PUT';
    data: FormData;
    onUploadProgress?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
}

interface DownloadRequestOptions<T = unknown> {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    data?: T;
    signal?: AbortSignal;
    onDownloadProgress?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
}

export interface IDownloadResponse {
    data: Blob;
    status: number,
    filename?: string
}


class ApiClient {

    protected config: AxiosRequestConfig;
    protected errorHandler: (reason: any) => void;
    protected instance: AxiosInstance;
    protected eventBus: EventBus;

    constructor(baseUrl: string, errorHandler?: (reason: any) => void, updatedAuthorization?: (value: string) => void) {
        this.config = {
            baseURL: baseUrl
        };
        this.errorHandler = errorHandler;
        this.instance = axios.create();
        const eventBus = new EventBus();
        this.eventBus = eventBus;
        this.instance.interceptors.response.use((response) => {
            if (response.headers.authorization) {
                const willCallback = updatedAuthorization && !this.config.headers || this.config.headers.Authorization !== response.headers.authorization
                this.setAuthorization(response.headers.authorization);
                if (willCallback) {
                    updatedAuthorization(response.headers.authorization);
                }
            }
            return response
        }, async function (error) {
            if (error && error.response) {
                if (error.response.status === 401) {
                    eventBus.emit("response-unauthorized");
                }
            }
            return Promise.reject(error);
        });
    }

    public registerEvent(eventName: string, callback: () => void) {
        this.eventBus.addListener(eventName, callback);
    }

    public unregisterEvent(eventName, callback) {
        this.eventBus.removeListener(eventName, callback);
    }

    public setAuthorization(token?: string) {
        if (!this.config.headers) {
            this.config.headers = {};
        }
        if (token == null && this.config.headers.hasOwnProperty('Authorization')) {
            delete this.config.headers.Authorization;
        } else {
            this.config.headers.Authorization = token;
        }
        this.eventBus.emit("updated-auth-token");
    }

    public setCsrfToken(token?: string) {
        if (!this.config.headers) {
            this.config.headers = {};
        }
        if (token == null && this.config.headers.hasOwnProperty('X-CSRFToken')) {
            delete this.config.headers['X-CSRFToken'];
        } else {
            this.config.headers['X-CSRFToken'] = token;
        }
        this.eventBus.emit("updated-csrf-token");
    }

    public getAuthorizationToken() {
        return this.config.headers && this.config.headers.Authorization ? this.config.headers.Authorization : null;
    }

    private onError(reason: any) {
        if (this.errorHandler != null) {
            this.errorHandler(reason);
        }
    }

    public get(endpoint: string, ctrl?: AbortController) {
        let config = this.config;
        if (ctrl) {
            config = {...config}
            config.signal = ctrl.signal;
        }
        return this.instance.get(endpoint, config);
    }

    public post(endpoint: string, data: object) {
        return this.instance.post(endpoint, data, this.config);
    }

    public put(endpoint: string, data: object, headers?: any) {
        let config = this.config;
        if (headers) {
            config = Helpers.deepCopy(config);
            config.headers = {...(config.headers || {}), ...headers};
        }
        return this.instance.put(endpoint, data, config);
    }

    public delete(endpoint: string) {
        return this.instance.delete(endpoint, this.config);
    }

    private async fetchEventSource(endpoint: string, method: string, onMessage: (e: EventSourceMessage) => void, ctrl?: AbortController) {
        if (!ctrl) {
            ctrl = new AbortController();
        }
        const headers = {
            Accept: "application/json, text/event-stream",
            ...this.config.headers
        };
        try {
            const response = await fetch(endpoint, {
                method,
                // @ts-ignore
                headers,
                signal: ctrl.signal
            });
            if (response.ok && (response.status === 200)) {
            } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
            ) {
                try {
                    // @ts-ignore
                    response.errorResponseData = await response.json();
                } catch (ex) {
                    // @ts-ignore
                    response.errorResponseData = await response.text();
                }
                throw response;
            }
            const LastEventId = 'last-event-id';
            await getBytes(response.body, getLines(getMessages(id => {
                if (id) {
                    headers[LastEventId] = id;
                } else {
                    delete headers[LastEventId];
                }
            }, retry => {
            }, onMessage)))
        } finally {
            if (!ctrl.signal.aborted) {
                ctrl.abort();
            }
        }
    }

    public async deleteWithEventSource(endpoint: string, onMessage: (e: EventSourceMessage) => void) {
        return this.fetchEventSource(`${this.config.baseURL}${endpoint}`, 'DELETE', onMessage);
    }

    public async getWithEventSource(endpoint: string, onMessage: (e: EventSourceMessage) => void, ctrl?: AbortController) {
        return this.fetchEventSource(`${this.config.baseURL}${endpoint}`, 'GET', onMessage, ctrl);
    }

    public uploadRequest<T>(options: UploadRequestOptions): Promise<{ data: T; status: number }> {
        const {onUploadProgress: onProgress, data, url} = options;

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('error', (error) => reject(error));
            xhr.addEventListener('load', () => {
                if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 300) {
                    resolve({data: xhr.response, status: xhr.status});
                } else {
                    reject({text: xhr.statusText, status: xhr.status, response: xhr.response});
                }
            });

            if (onProgress) {
                xhr.upload.addEventListener('progress', (event) => onProgress(event));
            }
            xhr.open(options.method || 'POST', `${this.config.baseURL}${url}`);
            for (const key of Object.keys(this.config.headers)) {
                xhr.setRequestHeader(key, this.config.headers[key]);
            }
            xhr.responseType = 'json';
            xhr.send(data);
        });
    };

    public downloadRequest<TBody = unknown>(options: DownloadRequestOptions<TBody> | string) {
        if (typeof options === 'string') {
            options = {url: options};
        }

        const {signal, method, url, data: body, onDownloadProgress: onProgress} = options;

        return new Promise<IDownloadResponse>((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.addEventListener('error', (error) => reject(error));
            xhr.addEventListener('abort', () => reject('aborted'));
            xhr.addEventListener('load', () => {
                if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 300) {
                    const ret: IDownloadResponse = {data: xhr.response as Blob, status: xhr.status}
                    const disposition = xhr.getResponseHeader('Content-Disposition');
                    let filename = '';
                    if (disposition) {
                        const filenameRe = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const filenameMatch = filenameRe.exec(disposition);
                        if (filenameMatch && filenameMatch[1]) {
                            ret.filename = filenameMatch[1].replace(/['"]/g, '');
                        }
                    }
                    resolve(ret);
                } else {
                    reject({text: xhr.statusText, status: xhr.status, response: xhr.response});
                }
            });

            if (onProgress) {
                xhr.addEventListener('progress', (event) => onProgress(event));
            }

            if (signal) {
                signal.addEventListener('abort', () => xhr.abort());
            }

            xhr.open(method || 'GET', `${this.config.baseURL}${url}`);
            for (const key of Object.keys(this.config.headers)) {
                xhr.setRequestHeader(key, this.config.headers[key]);
            }
            xhr.responseType = 'blob';

            if (body) {
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.send(JSON.stringify(body));
            } else {
                xhr.send();
            }
        });
    }
}

export default ApiClient;